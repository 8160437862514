<template>
  <v-card class="d-flex flex-column flex-grow-1">
      <v-card-text class="d-flex flex-grow-1">
          <iframe 
            class="flex-grow-1"
          src="//jobs.jobvite.com/vipdeskconnect/?nl=1" frameborder="0"
          ></iframe>
      </v-card-text>
  </v-card>
</template>

<script>
export default {

}
</script>

<style>

</style>