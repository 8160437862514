<template>
  <v-card   class="flex-grow-1 d-flex flex-column"
    style="max-height: calc(100vh - 80px)">
      <v-card-text>
           <iframe
          width="100%"
          height="100%"
          style="height: calc(100vh - 110px)"
          class="flex-grow-1"
          src="https://vip-portal.web.app/monitoring"
          frameborder="0"
        />
      </v-card-text>
  </v-card>
</template>

<script>
export default {

}
</script>

<style>

</style>